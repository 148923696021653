<template>
  <div>
    <vs-row vs-justify="center">
      <vs-col type="flex" vs-justify="center" vs-align="center" vs-w="12">
        <vs-card class="cardx">
          <div class="table-container">
            <vs-table multiple v-model="selected_table_data" pagination max-items="10" search :data="inventoryData">
              <template slot="header">
                <vs-row class="gap" style="bottom: 10px; position: relative;max-width: 75%;">
                  <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="4">
                    <h3>City Inventory</h3>
                  </vs-col>
                  <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="8">
                    <!-- <vs-dropdown vs-custom-content vs-trigger-click class="tree3 mr-6">
                      <a class="a-icon" href.prevent @click="showTreeBox">
                        City
                        <i class="material-icons">expand_more</i>
                      </a>
                      <vs-dropdown-menu class="customDropDown" style="padding: 0px">
                        <treeselect v-model="initSelectedCity" :options="treeDataCity" :multiple="true"
                          :value-consists-of="'LEAF_PRIORITY'" valueFormat="object" />
                      </vs-dropdown-menu>
                    </vs-dropdown> -->

                    <vs-dropdown vs-custom-content vs-trigger-click class="tree3">
                      <a class="a-icon" href.prevent @click="showTreeBox">
                        Category
                        <i class="material-icons">expand_more</i>
                      </a>
                      <vs-dropdown-menu class="customDropDown" style="padding: 0px">
                        <treeselect v-model="seleted_category" :options="categories_option" :multiple="true"
                          :value-consists-of="'LEAF_PRIORITY'" valueFormat="object" />
                      </vs-dropdown-menu>
                    </vs-dropdown>
                    <vs-button color="#6200ee" icon="search" style="margin: 0 0 0 5px; " @click="courseFilter()"
                      :disabled="selectedCategory.length == 0"></vs-button>
                    <vs-button color="#6200ee" style="margin: 0 0 0 5px" @click="clearSelectCategory" icon="close"
                      :disabled="selectedCategory.length == 0">
                    </vs-button>
                  </vs-col>
                  <vs-row style="margin: 4% 0 2% 0" v-if="selectedCategory.length != 0">
                    <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="2">
                      <b>Search Criteria:</b>
                    </vs-col>
                    <vs-col
                      style="overflow-y: scroll;border:1px solid rgb(210, 210, 210);padding: 5px 10px;border-radius: 10px;"
                      vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="10">
                      <div v-for="(chip, index) in selectedCategory" :key="index">
                        <vs-chip v-if="chip !== 'divider'" closable @click="removechip(chip, 'category')" color="primary">
                          <b>{{ chip }}</b>
                        </vs-chip>
                        <div v-else>
                          <div class="vl"></div>
                        </div>
                      </div>
                    </vs-col>
                  </vs-row>
                  <!-- :disabled="!courses.length"  -->
                </vs-row>

              </template>
              <template slot="thead">
                <vs-th class="first-col"> SKU </vs-th>
                <vs-th class="col-res"> Category </vs-th>
                <vs-th class="third-col"> Stock in Hand </vs-th>
                <vs-th class="col-res" v-for="citi in cities" :key="generateKey(citi)"> {{ citi }} </vs-th>
                <vs-th class="col-res"> Total Quantity</vs-th>

              </template>
              <template slot-scope="{ data }">
                <vs-tr :data="tr" :key="generateKey(indextr)" v-for="(tr, indextr) in data">
                  <vs-td class="first-col" :data="data[indextr].sku_name">
                    {{ data[indextr].sku_name }}
                  </vs-td>
                  <vs-td :data="data[indextr].category">
                    {{ data[indextr].category }}
                  </vs-td>
                  <vs-td class="third-col" :data="data[indextr].stock_in_hand">
                    {{ data[indextr].stock_in_hand }}
                  </vs-td>
                  <vs-td :data="data[indextr].city" v-for="citi in data[indextr].city_data" :key="generateKey(citi)">
                    <div v-for="city in cities" :key="generateKey(city)">
                      {{ citi[city] }}
                    </div>
                  </vs-td>
                  <vs-td :data="data[indextr].total_quantity">
                    {{ data[indextr].total_quantity }}
                  </vs-td>
                </vs-tr>
              </template>
            </vs-table>
            <vs-row>
              <vs-col vs-type="flex" vs-justify="center" vs-align="center">
                <vs-button color="#af7ef6" icon="local_shipping" size="large" @click="confirmCity(selected_table_data)"
                  v-if="selected_table_data != 0">Create
                  Dispatch</vs-button>
              </vs-col>
            </vs-row>
          </div>
        </vs-card>
      </vs-col>
    </vs-row>
    <vs-popup class="confirmationPopup" fullscreen title="Dispatch Request" background-color-popup="#f2f2f2"
      :active.sync="addUnitPopup">
      <div @click="addUnitPopup = false;selectedPopupCity = null">
        <vs-icon icon="close" size="medium" style="color: lightgrey;margin-left:98%;font-weight: bolder;"></vs-icon>
      </div>
      <vs-row class="mb-12">
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
          <h1>Dispatch Request [ {{ selected_table_data.length }} Books ]</h1>
        </vs-col></vs-row>
      <hr />
      <vs-row>
        <vs-col vs-type="flex" vs-justify="start" vs-align="center" vs-w="5">
          <h3 class="header-col">SKU</h3>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="1.5">
          <h3 class="header-col">Quantity</h3>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="2">
          <h3 class="header-col">Stock in Hand</h3>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="2">
          <h3 class="header-col">City</h3>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="1.5">
          <h3 class="header-col">Category</h3>
        </vs-col>

      </vs-row>
      <hr />
      <vs-row class="my-4" :key="generateKey(indextr)" v-for="(tr, indextr) in selected_table_data">
        <vs-col vs-type="flex" vs-justify="start" vs-align="center" vs-w="5">
          <h4 class="header-col">{{ tr.sku_name }}</h4>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="1.5">
          <div class="quantity">
            <input v-model="selected_table_data[indextr].quantity"   style="max-width:24% !important;padding:2%" @input="onInput($event, indextr)" type="text">
          </div>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="2">
          <h4 class="header-col">{{ tr.stock_in_hand }}</h4>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="2">
          <h4 class="header-col">{{ selectedPopupCity }}</h4>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="1.5">
          <h4 class="header-col">{{ tr.category }}</h4>
        </vs-col>
      </vs-row>
      <hr />

      <div style="display: flex;justify-content: flex-end;"><vs-button color="#a9f2a9"
          style="font-weight:bold;font-size:15px;color:rgb(255, 255, 255)" @click="SubmitAddUnitConfirmation()">submit
          request</vs-button></div>
    </vs-popup>
    <vs-popup class="confirmationPopup" title="Add Unit" :active.sync="confirmCityPopup"
      style="margin:0 0 auto 0;padding: 0 0 auto 0;">
      <div class="speech bottom"><vs-icon icon="local_shipping" size="100px" style="margin:-40% auto auto 40%"></vs-icon>
      </div>
      <vs-row class="gap">
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
          <h3>Dispatch Request ! </h3>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
          <p class="confirm-desc">Create a dispatch requset to &nbsp;</p>
          <v-select autocomplete v-model="selectedPopupCity" placeholder="City" class="v-selectt"
            style="position:absolute;border: none;width:250px;z-index:1600" :options="city_list"
            :reduce="(option) => option.id">
          </v-select>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12" class="no-padd">
          <vs-button :disabled="selectedPopupCity == null" @click="addUnit()">Confirm</vs-button>
        </vs-col>
      </vs-row>

    </vs-popup>
    <vs-popup class="confirmationPopup" title="Add Unit" :active.sync="confirmation_popup">
      <vs-row class="gap">
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
          <h3 style="color: rgb(168, 164, 164);">Are you sure You want to send ? </h3>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
          <vs-list>
            <vs-list-header :title="selectedPopupCity"></vs-list-header>
            <vs-list-item :title="sku.sku_name" :subtitle="'X '+sku.quantity" v-for="sku in selected_table_data" :key="sku.sku_id"></vs-list-item>
          </vs-list>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="6" class="no-padd">
          <vs-button @click="confirmation_popup = false; selectedPopupCity = null" type="border" class="confirm-btn">Cancel</vs-button>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="6" class="no-padd">
          <vs-button class="confirm-btn"  @click="SubmitAddUnit()">Confirm</vs-button>
        </vs-col>
      </vs-row>
    </vs-popup>
  </div>
</template>
  
<script>
import vSelect from "vue-select";
import Treeselect from "@riophae/vue-treeselect";
import { VTree, VSelectTree } from "vue-tree-halower";
import "vue-tree-halower/dist/halower-tree.min.css";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import ApiServices from "../ApiServices";
export default {
  components: {
    Treeselect,
    VTree,
    VSelectTree,
    "v-select": vSelect,
  },
  data() {
    return {
      confirmation_popup: false,
      selectedPopupCity: null,
      city_list: [{
        id: "Ahmedabad",
        label: "Ahmedabad",
      },
      {
        id: "Bangalore",
        label: "Bangalore",
      },
      {
        id: "Chennai",
        label: "Chennai",
      },
      {
        id: "Delhi",
        label: "Delhi",
      },
      {
        id: "Ernakulam",
        label: "Ernakulam",
      },
      {
        id: "Hyderabad",
        label: "Hyderabad",
      },
      {
        id: "Kolkata",
        label: "Kolkata",
      },
      {
        id: "Mumbai",
        label: "Mumbai",
      },
      {
        id: "Pune",
        label: "Pune",
      }],
      selected_city: [],
      selected_table_data: [],
      inventoryData: [],
      spare_InventoryData: [],
      addUnitPopup: false,
      selectedUnitQuantity: 0,
      selectedUnitId: 0,
      selectedUnitTotalQuantity: 0,
      UnitAdditionNumber: "",
      UnitReduceNumber: "",
      selectedSkuName: "",
      seleted_category: [],
      selectedCategory: [],
      initSelectedCity: [],
      categories_option: [
        {
          id: "All",
          label: "All",
          children: [{ id: 'CPA', label: 'CPA' },
          { id: 'CMA', label: 'CMA' },
          { id: 'CFA', label: 'CFA' },
          { id: 'FRM', label: 'FRM' },
          { id: 'Digital Marketing', label: 'Digital Marketing' },
          { id: 'SHRM', label: 'SHRM' },
          { id: 'Wiley Black', label: 'Wiley Black' },
          { id: 'Product Management', label: 'Product Management' },
          { id: 'AI/ML SQL', label: 'AI/ML SQL' },
          { id: 'HBR', label: 'HBR' },
          { id: 'KPMG', label: 'KPMG' },
          { id: 'Misc', label: 'Misc' },
          { id: 'MAC', label: 'MAC' },
          { id: 'WCBA', label: 'WCBA' }]
        }],
      treeDataCity: [
        {
          id: "All",
          label: "All",
          children: [
            {
              id: "Ahmedabad",
              label: "Ahmedabad",
            },
            {
              id: "Bangalore",
              label: "Bangalore",
            },
            {
              id: "Chennai",
              label: "Chennai",
            },
            {
              id: "Delhi",
              label: "Delhi",
            },
            {
              id: "Ernakulam",
              label: "Ernakulam",
            },
            {
              id: "Hyderabad",
              label: "Hyderabad",
            },
            {
              id: "Kolkata",
              label: "Kolkata",
            },
            {
              id: "Mumbai",
              label: "Mumbai",
            },
            {
              id: "Pune",
              label: "Pune",
            },]
        }
      ],
      filter_data: [],
      findData: [],
      cities: [],
      confirmCityPopup: false
    }
  },
  mounted() {
    this.selectedCategory = []
    this.seleted_category = []
    this.selected_table_data = []
    this.selected_table_data = []
    this.selectedPopupCity = null
    this.tr_data = []
    this.getInventory();

  },
  watch: {
    initSelectedCity: function (val) {
      if (val.length != 0) {
        this.mergeAllSearch();
      } else if (val.length == 0) {
        this.selected_city = [];
      }
    },
    seleted_category: function (val) {
      if (val.length != 0) {
        this.mergeAllSearch();
      } else if (val.length == 0) {
        this.selectedCategory = [];
      }
    }
  },
  methods: {
    handleBlur() {
      this.$nextTick(() => {
        this.$refs.myInput.focus();
      });
    },
    onInput(event,i) {
      let quantity = event.target.value.replace(/\D/g, "");
      this.selected_table_data[i].quantity = quantity
      event.target.value = quantity
      if (Number(quantity) > this.selected_table_data[i].stock_in_hand) {
        this.$vs.notify({
          title: "Stock Exceeded",
          text: 'Unfortunately, The stock in hand is less.',
          color: "warning",
        });
        this.selected_table_data[i].quantity = quantity.substring(0, quantity.length - 1);
        event.target.value = this.selected_table_data[i].quantity
      }

    },
    generateKey(item) {
      return `${item}-${Math.random()}`;
    },
    SubmitAddUnitConfirmation() {
      let empty_quantity = true;
      this.selected_table_data.forEach(tr => {
        let quantity = tr.quantity
        if (quantity == '' || quantity == undefined) {
          empty_quantity = false;
        }
      });
      if (empty_quantity) {
        this.addUnitPopup = false;
        this.confirmation_popup = true
      } else {
        this.$vs.notify({
          title: "Quantity",
          text: 'Quantity cannot be null',
          color: "danger",
        });
      }
    },
    getInventory() {
      this.$vs.loading();
      ApiServices.getCityInventory(this.selectedCategory.toString())
        .then((response) => {
          this.spare_InventoryData = response.data;
          this.inventoryData = response.data;
          this.cities = new Set();
          response.data.forEach(sku => {
            sku.city_data.forEach(obj => {
              const keys = Object.keys(obj);
              keys.forEach(key => this.cities.add(key));
            });
          });


          this.filter_data = response.data.data;
          this.$vs.loading.close();
        })
        .catch((error) => {
          this.$vs.notify({
            title: "error",
            text: error,
            color: "danger",
          });
          this.$vs.loading.close();
        })
    },
    confirmCity(trData) {
      // this.selectedSkuName = trData.sku_name;
      // this.selectedUnitId = trData.sku_id;
      // this.selectedUnitTotalQuantity = trData.total_quantity;
      // this.selectedUnitQuantity = trData.quantity_in_stock;
      // this.selectedPopupCity = trData.city
      if (trData.length != 0 & trData != []) {
        trData.forEach(tr => {
          tr['quantity'] = ''
        });
        this.selected_table_data = trData
        this.confirmCityPopup = true;
      }
    },
    addUnit() {
      this.confirmCityPopup = false;
      this.addUnitPopup = true
    },
    SubmitAddUnit() {

      let obj = {
        city: this.selectedPopupCity,
        sku_ids: {

        }
      };
      this.selected_table_data.forEach(sku => {
        obj.sku_ids[sku.sku_id] = sku.quantity
      });
      ApiServices.generateCityWarehouseDispatch(obj)
        .then((response) => {
          console.log("responce", response);
          this.addUnitPopup = false;
          this.$vs.notify({
            title: "Dispatch request created successfully",
            text: "",
            color: "success",
          });
          this.UnitAdditionNumber = "";
          this.confirmation_popup = false
          this.getInventory();
        })
        .catch((error) => {
          this.confirmation_popup = false
          this.$vs.notify({
            title: "error",
            text: error,
            color: "danger",
          });
        });
        this.refreshdata();
    },
    courseFilter() {
      this.getInventory()
    },
    mergeAllSearch() {
      this.selected_city = [];
      this.initSelectedCity.forEach((sort) => {
        if (sort.label === "All") {
          this.treeDataCity[0].children.forEach((child) => {
            this.selected_city.push(child.label);
          });
        } else {
          this.selected_city.push(sort.label);
        }
      });

      this.selectedCategory = [];
      this.seleted_category.forEach((sort) => {
        if (sort.label === "All") {
          this.categories_option[0].children.forEach((child) => {
            this.selectedCategory.push(child.label);
          });
        } else {
          this.selectedCategory.push(sort.label);
        }
      });
    },
    removechip(chip, name) {
      if (name == "city") {
        for (let i = 0; i < this.initSelectedCity.length; i++) {
          if (this.initSelectedCity[i].label === chip) {
            this.initSelectedCity.splice(i, 1); // Remove the matching object
            this.selected_city.splice(i, 1);
            break; // Exit the loop after removing the first match
          }
        }
      }
      if (name == "category") {
        for (let i = 0; i < this.seleted_category.length; i++) {
          if (this.seleted_category[i].label === chip) {
            this.seleted_category.splice(i, 1); // Remove the matching object
            this.selectedCategory.splice(i, 1);
            break; // Exit the loop after removing the first match
          }
        }
      }
    },
    refreshdata() {
      this.selectedCategory = []
      this.seleted_category = []
      this.selected_table_data = []
      this.selected_table_data = []
      this.selectedPopupCity = null
      this.tr_data = []
      this.courseFilter()
    },
    clearSelectCategory() {
      this.cities = []
      this.initSelectedCity = []
      this.seleted_category = []
      this.inventoryData = [];
      this.spare_InventoryData = [];
      this.getInventory();
    },
    showTreeBox() {
      console.log("clicked");
      return this.treeDataBDE;
    }
  }

}
</script>
  
<style lang="scss">
@import "@/assets/scss/vuesax/pages/dropdown.scss";

.tree3 {
  padding: 5px;
  box-sizing: border-box;
  border: 1px solid #ccccdd;
  border-radius: 5px;
  overflow: auto;
  width: 200px;
}

.a-icon {
  outline: none;
  text-decoration: none !important;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  letter-spacing: 2px;
}

i {
  font-size: 18px;
  margin-left: auto;

}

.vs-dropdown--menu {
  width: 300px;
}

.input-span-placeholder {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700 !important;
  color: #000 !important;
}

.confirmationPopup>div>.vs-popup--header {
  display: none;

  .vs-popup--title {
    margin: 27px 0 0 30px;
  }

  i {
    margin-right: 10px !important;
  }
}

.confirm-desc {
  letter-spacing: 0.2px;
  line-height: 21px;
  font-size: 16px;
  margin: 15px 0 10px 0;
  color: grey;
}

.no-padd {
  margin: 20px 0 -7px 0;
}

.table-container {
  overflow-x: auto;
}

.col-res {
  position: relative;
}

.first-col {
  left: 0;
  background-color: #fff;
  z-index: 1;
  min-width: 325px !important;
  position: sticky;
}

.third-col {
  min-width: 82px !important
}

.con-input-search {
  margin-bottom: 68px;
}

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 0.6em 0;
  padding: 0;
}

.header-col {
  color: rgb(86, 86, 86);
}

.vs-input-number--button-plus>i,
.vs-input-number--button-less>i {
  padding: 2px 5px 0 0 !important;
}

.quantity {
  display: flex;
  justify-content: center
}

.quantity>div>div>input {
  width: 90px;
}

.speech {
  font-size: 1.1em;
  color: #fff;
  background: #b8b2f7;
  position: relative;
  padding: 20% 20px 20px 20px;
  border-radius: 10px;
  width: 100%;
  margin: 0 auto;
}

.speech::after {
  border: 20px solid transparent;
  content: "";
  position: absolute;
}

.bottom.speech::after {
  border-top-color: #b8b2f7;
  border-bottom: 0;
  margin-left: -20px;
  left: 50%;
  bottom: -20px;
}

.v-selectt {
  max-width: 65px !important;
  margin: 5px 0 0 275px;

  .vs__dropdown-menu {
    max-height: 160px !important;
  }
}

.v-selectt>div {
  border: none !important;

  div>input {
    font-size: 1.2em;
    color: #7367f0;
    font-weight: bold;
  }

}
.confirm-btn{
  border-radius:17px
}

.v-selectt>div>.vs__actions>svg {
  display: none;
}
</style>